import { $, $$, on } from "../../../utils/luna"

export const Nav = () => {
  let isOpen = false

  const $html = document.documentElement
  const $$toggles = $$(".Nav [data-action='toggle-nav']")
  const $menu = $(".Nav__menu")
  const $$measures = $$(".Nav__measure")

  const update = () => {
    const height = Math.max(...$$measures.map($el => $el.scrollHeight))
    $html.style.setProperty("--nav-pane-height", height + "px")
  }

  const toggleMenu = () => {
    isOpen = !isOpen
    $menu.classList.toggle("is-open", isOpen)
  }

  update()
  on($$toggles, "click", toggleMenu)
  on(window, "resize", update)
}