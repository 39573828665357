import { $$ } from "../../../utils/luna"

// Config
const AEST_OFFSET_HOURS = (new Date() >= new Date("2023-10-01T02:00:00+10:00")) ? 11 : 10 // https://www.timeanddate.com/time/change/australia?year=2023
const UPDATE_INTERVAL_MS = 5000

// Constants
const MINUTE_IN_MILLISECONDS = 60 * 1000
const HOUR_IN_MILLISECONDS = 60 * MINUTE_IN_MILLISECONDS

const pad = (n: number) => n.toString().padStart(2, "0")

export const AESTTime = () => {
  let lastmm: number

  const $$els = $$(".AESTTime")

  if (!$$els.length) return

  const update = () => {
    const d = new Date() // create Date object for user's current location
    const utc = d.getTime() + d.getTimezoneOffset() * MINUTE_IN_MILLISECONDS // get UTC time in ms
    const aest = new Date(utc + HOUR_IN_MILLISECONDS * AEST_OFFSET_HOURS)

    const hours = aest.getHours()
    const mm = aest.getMinutes()

    if (lastmm === mm) return
    lastmm = mm

    const hh = hours <= 12 ? hours : hours - 12
    const meridiem = hours < 12 ? "AM" : "PM"

    $$els.forEach($el => {
      requestAnimationFrame(() => {
        $el.innerHTML = `<span class="AESTTime__clock" style="--h:${(hh + mm / 60) / 12 * 360 + "deg"};--m:${mm / 60 * 360 + "deg"}"></span> ${pad(hh)}:${pad(mm)} ${meridiem} / <abbr title="Australian Eastern Standard Time">AEST</abbr>`
      })
    })
  }

  update()
  setInterval(update, UPDATE_INTERVAL_MS)
}
