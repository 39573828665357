import { $$, findAll } from "../../../utils/luna"

export const MediaFutureDisplayBlock = () => {
  const $$blocks = $$(".MediaFutureDisplayBlock__carousel")

  const clearIntervals = $$blocks.map(($block) => {
    const delay = parseInt($block.dataset.delay, 10)
    const $$imgs = findAll($block, "img")
    const len = $$imgs.length

    let currIx = 0
    let intervalID: any

    const nextSlide = () => {
      currIx = currIx >= len - 1 ? 0 : currIx + 1
      requestAnimationFrame(() => {
        $$imgs.forEach(
          ($img, ix) => ($img.style.opacity = ix === currIx ? "1" : "0")
        )
      })
    }

    setInterval(nextSlide, delay)

    return () => {
      clearInterval(intervalID)
    }
  })

  return () => {
    clearIntervals.forEach((clear) => clear())
  }
}
